import React from "react";

function Footer() {
  return (
    <div className="flex flex-col gap-y-1 text-nowrap p-6 text-xs opacity-50 dark:opacity-30 md:px-0 md:pb-0 md:pt-20 md:text-right">
      {/* <div>
        <span>Built and styled with </span>
        <span className="font-semibold">
          <a href="https://react.dev/" target="_blank" rel="noreferrer">
            React
          </a>
        </span>
        <span> and </span>
        <span className="font-semibold">
          <a href="https://tailwindcss.com/" target="_blank" rel="noreferrer">
            Tailwind
          </a>
        </span>
        <span> using </span>
        <span className="font-semibold">
          <a
            href="https://fonts.google.com/specimen/Barlow"
            target="_blank"
            rel="noreferrer"
          >
            Barlow
          </a>
        </span>
        <span> and </span>
        <span className="font-semibold">
          <a href="https://heroicons.com/" target="_blank" rel="noreferrer">
            Heroicons
          </a>
        </span>
        <span className="opacity-50">.</span>
      </div> */}
      <div>Copyright &copy; 2024, Mouhany. All rights reserved.</div>
    </div>
  );
}

export default Footer;
