import React, { useEffect, useState } from "react";

function Navigation({ aboutRef, skillsRef, projectsRef, experienceRef, closeMenu }) {
  const [hovered, setHovered] = useState(null);
  const [active, setActive] = useState(0);

  const navs = [
    {
      link: "#about",
      name: "About",
      ref: aboutRef,
    },
    {
      link: "#skills",
      name: "Skills",
      ref: skillsRef,
    },
    {
      link: "#projects",
      name: "Projects",
      ref: projectsRef,
    },
    {
      link: "#experience",
      name: "Experience",
      ref: experienceRef,
    },
  ];

  useEffect(() => {
    const handleScroll = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const index = navs.findIndex(
            (nav) => nav.ref.current === entry.target,
          );
          setActive(index);
        }
      });
    };

    const observer = new IntersectionObserver(handleScroll, {
      threshold: 0.95,
    });

    navs.forEach((nav) => observer.observe(nav.ref.current));

    return () => {
      navs.forEach((nav) => observer.unobserve(nav.ref.current));
    };
  }, [navs]);

  const handleNavClick = (index) => {
    setActive(index);
    navs[index].ref.current.scrollIntoView({ behavior: "smooth" });
    closeMenu();
  };

  return (
    <nav className="menu hidden md:flex">
      <ul className="flex flex-col gap-y-3 md:gap-y-5">
        {navs.map((nav, index) => (
          <li
            key={index}
            onMouseEnter={() => setHovered(index)}
            onMouseLeave={() => setHovered(null)}
            onClick={(e) => {
              e.preventDefault();
              handleNavClick(index);
            }}
          >
            <a
              href={nav.link}
              aria-label={nav.name}
              className={`${(hovered !== null || active !== index) && hovered !== index ? "opacity-60" : ""} ${hovered === index ? "opacity-100" : ""} ${active === index ? "underline decoration-vulcan-400 decoration-2 underline-offset-[3px] dark:decoration-haze-600" : ""} link underline-animation  transition-opacity duration-300 ease-in-out hover:text-vulcan-800 active:text-vulcan-800 dark:hover:text-haze-200 dark:active:text-haze-200`}
            >
              {nav.name}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default Navigation;
