import { ArrowUpRightIcon } from "@heroicons/react/20/solid";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";

const projects = [
  {
    year: "2022",
    title: "Recifilter",
    description:
      "A recipe-filtering web application that lets users, with or without complex dietary need, find and bookmark recipes from the internet by ingredients.",
    tech: ["HTML", "CSS", "Javascript", "Python", "Flask", "SQL"],
    static: "preview/static_recifilter.webp",
    preview: "preview/recifilter.gif",
    code: "https://github.com/mouhany/recifilter",
    demo: "https://youtu.be/pKWqBQl-LmM",
    live: "https://recifilter.pythonanywhere.com/",
  },
  {
    year: "2023",
    title: "Instagram Scraper",
    description:
      "A Python program that scrapes and stores Instagram posts' metadata from a profile into a CSV / database file.",
    tech: ["Python", "Beautiful Soup", "Selenium", "SQL"],
    static: "preview/static_ig_scraper.webp",
    preview: "preview/ig_scraper.gif",
    code: "",
    demo: "https://youtu.be/rD8VCxQsC5w",
    live: "",
  },
  {
    year: "2024",
    title: "Muted Mirage",
    description:
      "A dark VS Code theme with neutral, moderately muted syntax colors designed to reduce eye strain and provide a comfortable coding experience.",
    tech: ["JSON", "VS Code", "npm", "vsce"],
    static: "preview/muted-mirage-no-shadow.png",
    preview: "preview/muted-mirage-no-shadow.png",
    code: "https://github.com/mouhany/muted-mirage",
    demo: "https://vscode.dev/editor/theme/mouhany.muted-mirage/Muted%20Mirage",
    live: "https://marketplace.visualstudio.com/items?itemName=mouhany.muted-mirage",
  },
  {
    year: "2024",
    title: "Business Cards 🐈",
    description: "A simple static page I made to learn React and CI/CD.",
    tech: ["React", "Vite", "npm", "Tailwind CSS", "CI/CD"],
    static: "preview/static_purrofessional_business_cards.png",
    preview: "preview/purrofessional_business_cards.gif",
    code: "https://github.com/mouhany/business-cards",
    demo: "",
    live: "https://mouhany.github.io/business-cards/",
  },
  {
    year: "2023",
    title: "Network",
    description:
      "A social network platform where users can create posts, follow other users, and like or comment on posts.",
    tech: ["HTML", "CSS", "MDBootstrap", "Javascript", "Python", "Django", "ORM"],
    static: "preview/static_network.webp",
    preview: "preview/network.gif",
    code: "https://www.github.com/mouhany/network",
    demo: "https://youtu.be/BL0ik9EYlYw",
    live: "",
  },
  {
    year: "2023",
    title: "Mail",
    description:
      "An email client that makes API calls with Javascript to send and receive emails.",
    tech: ["HTML", "CSS", "Bootstrap", "Javascript", "Python", "Django", "ORM"],
    static: "preview/static_mail.webp",
    preview: "preview/mail.gif",
    code: "https://www.github.com/mouhany/mail",
    demo: "https://youtu.be/roNlDxW3WNM",
    live: "",
  },
  {
    year: "2023",
    title: "Commerce",
    description:
      "An e-commerce website where users can create listings, place bids, and post comments.",
    tech: ["HTML", "CSS", "Bootstrap", "Javascript", "Python", "Django", "ORM"],
    static: "preview/static_commerce.webp",
    preview: "preview/commerce.gif",
    code: "https://www.github.com/mouhany/commerce",
    demo: "https://youtu.be/CA5mGlBlIX0",
    live: "",
  },
  {
    year: "2023",
    title: "Wiki",
    description:
      "A Wikipedia-like online encyclopedia where user can add, edit, and search for encyclopedia entries.",
    tech: ["HTML", "CSS", "Javascript", "Python", "Django"],
    static: "preview/static_wiki.webp",
    preview: "preview/wiki.gif",
    code: "https://www.github.com/mouhany/wiki",
    demo: "https://youtu.be/h28xO9zp7-o",
    live: "",
  },
  {
    year: "2023",
    title: "Google Clone",
    description: "A responsive front-end clone of Google's website.",
    tech: ["HTML", "CSS"],
    static: "preview/static_search.webp",
    preview: "preview/search.gif",
    code: "https://www.github.com/mouhany/google-clone",
    demo: "https://youtu.be/nWRTI7FQlSE",
    live: "https://mouhany.github.io/google-clone/",
  },
  {
    year: "2021",
    title: "Rocketman",
    description:
      "A responsive version of a mockup landing page I created to learn CSS.",
    tech: ["HTML", "CSS", "Javascript"],
    static: "preview/static_rocketman.webp",
    preview: "preview/rocketman.gif",
    code: "https://www.github.com/mouhany/rocketman",
    demo: "",
    live: "https://mouhany.github.io/rocketman/",
  },
];

function Projects() {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [hovered, setHovered] = useState(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [showMore, setShowMore] = useState(2);

  const handleMouseEnter = (e, project, index) => {
    setHoveredIndex(index);
    setHovered(project);
    updateMousePosition(e);
  };
  const handleMouseMove = (e) => {
    updateMousePosition(e);
  };
  const handleMouseLeave = () => {
    setHoveredIndex(null);
    setHovered(null);
  };

  const updateMousePosition = (e) => {
    setMousePosition({ x: e.clientX, y: e.clientY });
  };

  const handleShowMore = () => {
    setShowMore(projects.length);
  };

  return (
    <div className="flex flex-col gap-y-3 md:gap-y-6">
      <div className="sticky top-0 z-20 bg-haze-50 p-6 py-6 dark:bg-vulcan-950 md:mt-0 md:px-3 md:pb-3 md:pt-28 lg:pt-20 lg:transition-bg lg:duration-300 lg:ease-in-out">
        <h3 className="link">Projects</h3>
      </div>
      <div className="flex flex-col gap-y-9 px-6 md:px-3">
        {projects.slice(0, showMore).map((project, index) => {
          const ProjectContainer = project.live ? "a" : "div";
          const containerProps = project.live
            ? {
                href: project.live,
                target: "_blank",
                "aria-label": `This project is live on ${project.live}`,
              }
            : {};

          return (
            <div key={index}>
              <ProjectContainer
                {...containerProps}
                className={`${hoveredIndex !== null && hoveredIndex !== index ? "opacity-60" : ""} element z-0 flex flex-col gap-y-1 transition-opacity duration-300 ease-in-out hover:text-vulcan-800 active:text-vulcan-800 dark:hover:text-haze-200 dark:active:text-haze-200`}
                onMouseEnter={(e) => handleMouseEnter(e, project, index)}
                onTouchStart={(e) => handleMouseEnter(e, project, index)} // For tablet / mobile
                onMouseLeave={handleMouseLeave}
                onMouseMove={handleMouseMove}
              >
                <div className="flex flex-row items-center gap-x-2">
                  <h2 className="font-bold tracking-wide">{project.title}</h2>
                  {project.live && (
                    <ArrowUpRightIcon className="arrow size-4 opacity-50" />
                  )}
                </div>
                <img
                  src={
                    hoveredIndex === index ? project.preview : project.static
                  }
                  alt={project.title}
                  className="mb-3 mt-1 aspect-video max-w-60 rounded-md object-cover md:max-w-72 lg:hidden"
                />
                {project.description && (
                  <p className="">{project.description}</p>
                )}
                {project.tech && (
                  <ul className="flex flex-row flex-wrap gap-x-4 text-sm text-vulcan-500 dark:text-haze-500">
                    {project.tech.map((techUsed, techIndex) => (
                      <li key={techIndex}>{techUsed}</li>
                    ))}
                  </ul>
                )}
                <ul className="flex flex-row gap-x-6 md:mt-1">
                  {project.code && (
                    <li className="opacity-50 hover:opacity-100">
                      <a
                        href={project.code}
                        className="link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Code
                      </a>
                    </li>
                  )}
                  {project.demo && (
                    <li className="opacity-50 hover:opacity-100">
                      <a
                        href={project.demo}
                        className="link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Demo
                      </a>
                    </li>
                  )}
                </ul>
              </ProjectContainer>
            </div>
          );
        })}
        {hovered && (
          <div
            className={`w-md fixed z-40 hidden rounded-lg shadow-md lg:block`}
            style={{
              top: 0.75 * mousePosition.y,
              left: 0.075 * (mousePosition.x + 390),
            }}
          >
            <img
              src={hovered.preview}
              alt={hovered.title}
              className="aspect-video max-w-sm rounded-md object-cover"
            />
          </div>
        )}
        {showMore < projects.length && (
          <div
            role="button"
            onClick={handleShowMore}
            className="project flex cursor-pointer flex-row items-center gap-x-3 hover:text-vulcan-800 dark:hover:text-haze-200"
            aria-label="Show More Projects"
          >
            <div className="link underline-animation">Show More</div>
            <ChevronDownIcon className="size-4 opacity-50" />
          </div>
        )}
      </div>
    </div>
  );
}

export default Projects;
