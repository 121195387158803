import { MoonIcon, SunIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";

function Toggler() {
  const prefersDarkScheme = window.matchMedia('(prefers-color-scheme: dark)').matches;
  const [darkMode, setDarkMode] = useState(prefersDarkScheme);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  useEffect(() => {
    const metaThemeColor = document.querySelector('meta[name="theme-color"]');
    if (darkMode) {
      document.documentElement.classList.add("dark");
      // document.body.classList.add("dark");
      metaThemeColor.setAttribute("content", "#101521");
    } else {
      document.documentElement.classList.remove("dark");
      // document.body.classList.remove("dark");
      metaThemeColor.setAttribute("content", "#f7f8f9");
    }
  }, [darkMode]);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

    const handleChange = (e) => {
      setDarkMode(e.matches);
    };

    mediaQuery.addEventListener('change', handleChange);

    return () => {
      mediaQuery.removeEventListener('change', handleChange);
    };
  }, []);

  return (
    <button
      className="-me-2 md:me-0 fixed bottom-6 right-6 z-50 flex size-12 items-center justify-center rounded-full shadow-md backdrop-blur-sm hover:bg-vulcan-500 hover:bg-opacity-10 hover:text-vulcan-800 dark:hover:bg-haze-500 dark:hover:bg-opacity-10 dark:hover:text-haze-200"
      onClick={toggleDarkMode}
      aria-label="Toggle theme"
    >
      {darkMode ? (
        <SunIcon className="size-6" aria-label="Toggle to light theme" />
      ) : (
        <MoonIcon className="size-6" aria-label="Toggle to dark theme" />
      )}
    </button>
  );
}

export default Toggler;
