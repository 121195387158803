import { ArrowUpRightIcon } from "@heroicons/react/20/solid";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";

const experiences = [
  {
    website: "https://dataannotation.tech/",
    location: "Remote",
    company: "Data Annotation",
    from: "2024",
    until: "Now",
    position: "AI Trainer",
    description: [
      "Train, evaluate, and optimize AI models for diverse projects across industries.",
    ],
  },
  {
    website: "https://nipponpaint-indonesia.com/",
    location: "Jakarta",
    company: "Nippon Paint Indonesia",
    from: "2018",
    until: "2022",
    position: "Color Stylist and Booth Designer",
    description: [
      "Curate and present customized paint color palettes tailored to client preferences, creating detailed mockups to ensure design alignment.",
      "Collaborate closely with the branding team to design and execute visually compelling, on-brand booths for exhibitions and retail spaces.",
      "Play a key role in enhancing brand presence and customer engagement by delivering aesthetically consistent and innovative design solutions across various client projects."
    ],
  },
  {
    website: "https://djuhara.com/",
    location: "Tangerang",
    company: "djuhara + djuhara",
    from: "2017",
    until: "2017",
    position: "Architect Intern",
    description: [
      "Assisted the principal and project architects with various tasks such as 3D modeling, CAD drawings, and scale model creation.",
    ],
  },
];

function Experience() {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  // const [hovered, setHovered] = useState(null);
  const [showMore, setShowMore] = useState(2);

  const handleMouseEnter = (experience, index) => {
    setHoveredIndex(index);
    // setHovered(experience);
  };
  const handleMouseLeave = () => {
    setHoveredIndex(null);
    // setHovered(null);
  };

  const handleShowMore = () => {
    setShowMore(experiences.length);
  };

  return (
    <div className="flex flex-col gap-y-1 md:gap-y-6">
      <div className="sticky top-0 z-20 bg-haze-50 p-6 py-6 dark:bg-vulcan-950 md:mt-0 md:px-3 md:pb-3 md:pt-28 lg:pt-20 lg:transition-bg lg:duration-300 lg:ease-in-out">
        <h3 className="link">Experience</h3>
      </div>
      <div className="flex flex-col gap-y-12 px-6 md:px-3">
        {experiences.slice(0, showMore).map((experience, index) => (
          <a
            href={experience.website}
            target="_blank"
            rel="noopener noreferrer"
            key={index}
            aria-label={`Go to ${experience.company}'s website`}
            className={`${hoveredIndex !== null && hoveredIndex !== index ? "opacity-60" : ""} element flex flex-col gap-y-1 transition-opacity duration-300 ease-in-out hover:text-vulcan-800 active:text-vulcan-800 dark:hover:text-haze-200 dark:active:text-haze-200 lg:grid lg:grid-cols-12 lg:gap-x-4`}
            onMouseEnter={() => handleMouseEnter(experience, index)}
            onTouchStart={() => handleMouseEnter(experience, index)} // For tablet / mobile
            onMouseLeave={handleMouseLeave}
          >
            <div className="lg:col-span-2">
              <div className="text-nowrap text-sm lg:pt-[2px]">
                {experience.from} — {experience.until}
              </div>
            </div>
            <div className="flex flex-col gap-y-1 lg:col-span-10">
              <div className="flex flex-row items-center gap-x-2">
                <h2 className="font-bold tracking-wide">
                  {experience.position} at {experience.company}
                </h2>
                {/* <ArrowUpRightIcon className="arrow size-4 opacity-50" /> */}
              </div>
              <p className="text-sm text-vulcan-500 dark:text-haze-500">
                {experience.location}
              </p>
              {experience.description && (
                <div className="mt-1 flex flex-col gap-y-3">
                  {experience.description.map((task) => (
                    <p>{task}</p>
                  ))}
                </div>
              )}
            </div>
          </a>
        ))}
        {showMore < experiences.length && (
          <div
            role="button"
            onClick={handleShowMore}
            className="element flex cursor-pointer flex-row items-center gap-x-3 hover:text-vulcan-800 dark:hover:text-haze-200"
            aria-label="Show More Experience"
          >
            <div className="link underline-animation">Show More</div>
            <ChevronDownIcon className="size-4 opacity-50" />
          </div>
        )}
      </div>
    </div>
  );
}

export default Experience;
