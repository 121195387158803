import React from "react";
import { useState } from "react";

const skills = [
  {
    title: "Languages",
    skillset: ["HTML", "CSS", "SCSS", "Javascript", "Python", "SQL"],
  },
  {
    title: "Frameworks",
    skillset: ["Django", "Flask", "React", "Tailwind CSS", "Bootstrap"],
  },
  {
    title: "Tools",
    skillset: ["Git / Github", "Package Management", "CI / CD"],
  },
  {
    title: "Design",
    skillset: [
      "3D Modeling",
      "Wireframing",
      "Photoshop",
      "Illustrator",
      "Figma",
    ],
  },
];

function Skills() {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (skill, index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  return (
    <div className="flex flex-col gap-y-3 md:gap-y-6">
      <div className="sticky top-0 z-20 bg-haze-50 p-6 py-6 dark:bg-vulcan-950 md:mt-0 md:px-3 md:pb-3 md:pt-28 lg:pt-20 lg:transition-bg lg:duration-300 lg:ease-in-out">
        <h3 className="link">Skills</h3>
      </div>
      <div className="grid grid-cols-2 justify-between gap-12 px-6 sm:grid-cols-4 md:grid-cols-2 md:px-3 lg:grid-cols-4 lg:gap-0">
        {skills.map((skill, index) => (
          <div
            key={index}
            className={`${hoveredIndex !== null && hoveredIndex !== index ? "lg:opacity-60" : ""} lg:hover:bg-vulcan-500/5 lg:hover:dark:bg-haze-500/5 transition-opacity duration-300 ease-in-out lg:hover:text-vulcan-800 lg:active:text-vulcan-800 lg:dark:hover:text-haze-200 lg:dark:active:text-haze-200 flex max-w-40 flex-col gap-3 rounded-md lg:p-3`}
            onMouseEnter={() => handleMouseEnter(skill, index)}
            onTouchStart={() => handleMouseEnter(skill, index)} // For tablet / mobile
            onMouseLeave={handleMouseLeave}
          >
            <div className="font-bold tracking-wide">{skill.title}</div>
            <ul className="flex flex-col text-sm text-vulcan-500 dark:text-haze-500">
              {skill.skillset.map((skill, skillIndex) => (
                <li key={skillIndex} className="">
                  {skill}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Skills;
