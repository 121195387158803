import { ArrowUpRightIcon } from "@heroicons/react/20/solid";
import React, { useState } from "react";

const socials = [
  {
    link: "mailto:mouhany@gmail.com",
    name: "Email",
  },
  {
    link: "https://gist.github.com/mouhany",
    name: "Gist",
  },
  {
    link: "https://github.com/mouhany",
    name: "Github",
  },
  {
    link: "/mouhany_resume_2024.pdf",
    name: "Resume",
  },
  // {
  //   link: "https://codepen.io/mouhany",
  //   name: "Codepen",
  // },
  // {
  //   link: "https://read.cv/hany",
  //   name: "Read.cv",
  // },
];

function Social() {
  const [hovered, setHovered] = useState(null);
  return (
    <ul className="menu flex hidden flex-row flex-wrap gap-x-6 gap-y-3 md:flex">
      {socials.map((social, index) => (
        <li key={index} className="element flex flex-row items-center gap-x-1">
          <a
            href={social.link}
            target="_blank"
            rel="noopener noreferrer"
            onMouseEnter={() => setHovered(index)}
            onMouseLeave={() => setHovered(null)}
            className={`${hovered !== null && hovered !== index ? "opacity-60" : ""} link rounded-md bg-vulcan-500/20 px-2 py-1 text-vulcan-700 hover:text-vulcan-800 active:text-vulcan-800 dark:bg-haze-500/20 dark:text-haze-300 dark:hover:text-haze-200 dark:active:text-haze-200 lg:transition lg:duration-300 lg:ease-in-out`}
            aria-label={social.name}
          >
            {social.name}
          </a>
          {/* <ArrowUpRightIcon className="hover-icon size-4 text-haze-600 dark:text-vulcan-300" /> */}
        </li>
      ))}
    </ul>
  );
}

export default Social;
