import React from "react";
import About from "./components/About";
import Skills from "./components/Skills";
import Experience from "./components/Experience";
import Footer from "./components/Footer";
import Projects from "./components/Projects";

function Main({ aboutRef, skillsRef, projectsRef, experienceRef }) {
  return (
    <main
      className="flex flex-col gap-y-16 pb-4 pt-0 md:w-3/5 md:gap-y-6 md:pb-24 md:pt-0 lg:gap-y-9 lg:pb-16"
      id="mainwrapper"
    >
      <section id="about" ref={aboutRef}>
        <About />
      </section>
      <section id="skills" ref={skillsRef}>
        <Skills />
      </section>
      <section id="projects" ref={projectsRef}>
        <Projects />
      </section>
      <section id="experience" ref={experienceRef}>
        <Experience />
      </section>
      <Footer />
    </main>
  );
}

export default Main;
