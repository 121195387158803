import React, { useRef } from "react";
import "./App.css";
import Header from "./Header";
import Main from "./Main";
import Toggler from "./Toggler";

function App() {
  const aboutRef = useRef(null);
  const skillsRef = useRef(null);
  const projectsRef = useRef(null);
  const experienceRef = useRef(null);

  return (
    <div className="App bg-haze-50 text-vulcan-700 selection:bg-vulcan-500/20 selection:text-vulcan-800 dark:bg-vulcan-950 dark:text-haze-300 dark:selection:bg-haze-500/20 dark:selection:text-haze-200 lg:transition lg:duration-300 lg:ease-in-out">
      <div className="relative mx-auto flex max-w-screen-xl flex-col justify-evenly md:flex-row md:gap-x-6 md:px-12 md:py-0 lg:px-16">
        <Header
          aboutRef={aboutRef}
          skillsRef={skillsRef}
          projectsRef={projectsRef}
          experienceRef={experienceRef}
        />
        <Main
          aboutRef={aboutRef}
          skillsRef={skillsRef}
          projectsRef={projectsRef}
          experienceRef={experienceRef}
        />
      </div>
      <Toggler />
    </div>
  );
}

export default App;
