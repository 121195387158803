import React from "react";
import { FaceSmileIcon } from "@heroicons/react/24/outline";

function About() {
  return (
    <div className="flex flex-col gap-y-3 md:gap-y-6">
      <div className="sticky top-0 z-20 mt-24 bg-haze-50 p-6 py-6 dark:bg-vulcan-950 md:mt-0 md:px-3 md:pb-3 md:pt-28 lg:pt-20 lg:transition-bg lg:duration-300 lg:ease-in-out">
        <h3 className="link">About</h3>
      </div>
      <div className="flex flex-col gap-y-9 px-6 leading-relaxed md:px-3">
        <p>
          I’m an architecture graduate turned multidisciplinary software developer based in
          Jakarta, Indonesia. Previously I’ve had experience working as a
          designer at a paint company, where I mainly work with color systems,
          Adobe softwares, and 3D design softwares to create custom booths for
          events and retail stores.
        </p>
        <p>
          I enjoy creating apps with intuitive UIs, clear visual hierarchy and
          seamless UX. If you share these values and need help with a web
          development project or Python script, I'm available for freelance project or remote position so feel free to{" "}
          <a
            href="mailto:mouhany@gmail.com"
            className="underline-animation flex flex-row items-center font-semibold flex-wrap"
          >
            reach out via email.
          </a>{" "}
        </p>
        <p className="flex flex-row items-center gap-1">
          Cheers
          <FaceSmileIcon className="size-4" />
        </p>
      </div>
    </div>
  );
}

export default About;
