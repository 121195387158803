import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState, useRef } from "react";
import Navigation from "./components/Navigation";
import Social from "./components/Social";

function Header({ aboutRef, skillsRef, projectsRef, experienceRef }) {
  const [hideNavbar, setHideNavbar] = useState(false);
  const [hideMenu, setHideMenu] = useState(true);
  const lastScrollTop = useRef(0);
  const threshold = 18;

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      const scrollDiff = Math.abs(scrollTop - lastScrollTop.current);

      if (scrollDiff > threshold) {
        // only hide / show navbar if the movement exceeds threshold, else do nothing
        if (scrollTop > lastScrollTop.current) {
          // scrolling down: swiping up to the bottom of the page
          setHideNavbar(true);
        } else {
          // scrolling up: swiping down the the top of the page
          setHideNavbar(false);
        }
        lastScrollTop.current = scrollTop <= 0 ? 0 : scrollTop;
      }
    };

    if (hideMenu) {
      window.addEventListener("scroll", handleScroll);
    } else {
      window.removeEventListener("scroll", handleScroll);
    }

    return () => window.removeEventListener("scroll", handleScroll);
  }, [hideMenu]);

  const toggleHideMenu = () => {
    setHideMenu(!hideMenu);
  };

  const closeMenu = () => {
    setHideMenu(true);
  };

  useEffect(() => {
    const menus = document.querySelectorAll(".menu");
    if (hideMenu) {
      menus.forEach((menu) => {
        menu.classList.add("hidden");
      });
      document
        .querySelector("#wrapper")
        .classList.remove(
          "h-full",
          "bg-opacity-80",
          "dark:bg-opacity-80",
          "backdrop-blur-md",
        );
      document
        .querySelector("#wrapper")
        .classList.add("bg-opacity-100", "dark:bg-opacity-100");
      document.querySelector("#toggleMenu").classList.remove("shadow-md");
    } else {
      menus.forEach((menu) => {
        menu.classList.remove("hidden");
      });
      document
        .querySelector("#wrapper")
        .classList.add(
          "h-full",
          "bg-opacity-80",
          "dark:bg-opacity-80",
          "backdrop-blur-md",
        );
      document
        .querySelector("#wrapper")
        .classList.remove("bg-opacity-100", "dark:bg-opacity-100");
      document.querySelector("#toggleMenu").classList.add("shadow-md");
    }
  }, [hideMenu]);

  return (
    <header
      id="wrapper"
      className={`${hideNavbar ? "-translate-y-full" : "translate-y-0"} fixed left-0 right-0 top-0 z-30 flex flex-col gap-y-9 bg-haze-50 bg-opacity-100 p-6 transition-transform duration-500 ease-in-out dark:bg-vulcan-950 dark:bg-opacity-100 md:sticky md:max-h-screen md:w-2/5 md:min-w-[330px] md:translate-y-0 md:justify-between md:px-0 md:py-24 lg:max-w-[350px] lg:py-16 lg:transition-bg lg:duration-300 lg:ease-in-out`}
    >
      <div className="flex flex-col gap-y-9 md:gap-y-6">
        <div className="flex flex-row items-center justify-between">
          <a
            href="/"
            className="flex flex-row items-center justify-center gap-x-3 text-nowrap lg:transition lg:duration-300 lg:ease-in-out"
          >
            <div className="mt-1 ikb -ms-2 size-9 rounded-full bg-ikb"></div>
            <div className="text-4xl font-bold normal-case tracking-wide text-vulcan-800 dark:text-haze-200 md:text-5xl">Hi, I'm Hany</div>
          </a>
          <button
            id="toggleMenu"
            onClick={toggleHideMenu}
            className="-me-2 flex size-12 items-center justify-center rounded-full hover:bg-vulcan-500/10 hover:text-vulcan-800 dark:hover:bg-haze-500/10 dark:hover:text-haze-200 md:hidden"
          >
            {hideMenu ? (
              <Bars3Icon className="size-6" />
            ) : (
              <XMarkIcon className="size-6" />
            )}
          </button>
        </div>
        <p className="hidden leading-relaxed md:block">
          I bring intuitive and human-centered design principles from
          architecture to software development.
        </p>
        <Social />
      </div>
      <Navigation
        aboutRef={aboutRef}
        skillsRef={skillsRef}
        projectsRef={projectsRef}
        experienceRef={experienceRef}
        closeMenu={closeMenu}
      />
    </header>
  );
}

export default Header;
